export const translations = {
  'en': {
    label: '© 2024 SearchCarHub. All rights reserved.'
  },
  'pt-br': {
    label: '© 2024 SearchCarHub. Todos os direitos reservados.'
  },
  'pt': {
    label: '© 2024 SearchCarHub. Todos os direitos reservados.'
  },
  'fr': {
    label: '© 2024 SearchCarHub. Tous droits réservés.'
  }
};
