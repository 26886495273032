export const translations = {
  'en': {
    welcome: 'Welcome to SearchCarHub',
    slogan: 'Connecting you with your perfect car',
  },
  'pt-br': {
    welcome: 'Bem-vindo(a) ao SearchCarHub',
    slogan: 'Conectando você com o carro perfeito',
  },
  'pt': {
    welcome: 'Bem-vindo(a) ao SearchCarHub',
    slogan: 'Conectando-o ao carro perfeito',
  },
  'fr': {
    welcome: 'Bienvenue chez SearchCarHub',
    slogan: 'Vous connecter à la voiture parfaite',
  }
};
