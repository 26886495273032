export const translations = {
  'en': {
    contact: 'Contact Us',
    contact_description: 'Have questions? Reach out to us directly through the information below or fill out the form to get in touch.',
    email: 'SearchCarHub@gmail.com',
    phone: '+1 (754) 366-9922',
  },
  'pt-br': {
    contact: 'Fale Conosco',
    contact_description: 'Tem dúvidas? Entre em contato diretamente conosco através das informações abaixo ou preencha o formulário para entrar em contato.',
    email: 'SearchCarHub@gmail.com',
    phone: '+1 (754) 366-9922',
  },
  'pt': {
    contact: 'Contacte-nos',
    contact_description: 'Tem perguntas? Entre em contacto diretamente connosco através das informações abaixo ou preencha o formulário para entrar em contacto.',
    email: 'SearchCarHub@gmail.com',
    phone: '+1 (754) 366-9922',
  },
  'fr': {
    contact: 'Contactez-Nous',
    contact_description: 'Vous avez des questions? Contactez-nous directement via les informations ci-dessous ou remplissez le formulaire pour nous joindre.',
    email: 'SearchCarHub@gmail.com',
    phone: '+1 (754) 366-9922',
  }
};
