export const translations = {
  'en': {
    company_name: 'SearchCarHub'
  },
  'pt-br': {
    company_name: 'SearchCarHub'
  },
  'pt': {
    company_name: 'SearchCarHub'
  },
  'fr': {
    company_name: 'SearchCarHub'
  }
};
