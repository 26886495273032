import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';

export const About: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const bgTheme = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light';
  const bgThemeWhite = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light';
  const bgThemeSecondary = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light';

  return (
    <>
      <Helmet>
        <title>{t('about:about')} - {t('header:company_name')}</title>
        <meta name="description" content="Saiba mais sobre a SearchCarHub e nossos valores." />
      </Helmet>
      <div className="space-y-10">
        <section className={`text-center py-10 ${bgTheme}`}>
          <h1 className="text-4xl font-bold">{t('about:about')}</h1>
          <p className="text-lg mt-2 mx-auto max-w-4xl">
            {t('about:about_description')}
          </p>
        </section>
        <section className={`text-center py-10 ${bgThemeWhite}`}>
          <div className="max-w-4xl mx-auto px-4">
            <h2 className="text-4xl font-bold">{t('about:our_mission')}</h2>
            <p className="text-lg mt-2 mx-auto max-w-4xl">
              {t('about:our_mission_description')}
            </p>
          </div>
        </section>
        <section className={`${bgThemeSecondary} py-10`}>
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold">{t('about:our_team')}</h2>
            {t('about:our_team_description')}
          </div>
        </section>
      </div>
    </>
  );
}
