export const translations = {
  'en': {
    about: 'About Us',
    about_description: 'Here at SearchCarHub, we strive to lead with our guiding principles and help spread ideas of sustainability and responsibility whose impact can reach far beyond our own business.',
    our_mission: 'Our Mission',
    our_mission_description: 'To deliver outstanding solutions that make a genuine difference to our clients.',
    our_team: 'Our Team',
    our_team_description: 'Meet the people who are dedicated to transforming our clients’ visions into reality.',
  },
  'pt-br': {
    about: 'Sobre Nós',
    about_description: 'Aqui na SearchCarHub, nos esforçamos para liderar com nossos princípios orientadores e ajudar a disseminar ideias de sustentabilidade e responsabilidade cujo impacto pode alcançar muito além do nosso próprio negócio.',
    our_mission: 'Nossa Missão',
    our_mission_description: 'Entregar soluções excepcionais que façam uma diferença genuína para nossos clientes.',
    our_team: 'Nossa Equipe',
    our_team_description: 'Conheça as pessoas dedicadas a transformar as visões de nossos clientes em realidade.',
  },
  'pt': {
    about: 'Sobre Nós',
    about_description: 'Aqui na SearchCarHub, esforçamo-nos por liderar com os nossos princípios orientadores e ajudar a disseminar ideias de sustentabilidade e responsabilidade cujo impacto pode ir muito além do nosso próprio negócio.',
    our_mission: 'Nossa Missão',
    our_mission_description: 'Fornecer soluções excecionais que façam uma diferença genuína para os nossos clientes.',
    our_team: 'Nossa Equipe',
    our_team_description: 'Conheça as pessoas dedicadas a transformar as visões dos nossos clientes em realidade.',
  },
  'fr': {
    about: 'À Propos',
    about_description: 'Chez SearchCarHub, nous nous efforçons de diriger avec nos principes directeurs et d’aider à propager des idées de durabilité et de responsabilité dont l\'impact peut aller bien au-delà de notre propre entreprise.',
    our_mission: 'Notre Mission',
    our_mission_description: 'Fournir des solutions exceptionnelles qui font une réelle différence pour nos clients.',
    our_team: 'Notre Équipe',
    our_team_description: 'Rencontrez les personnes dédiées à transformer les visions de nos clients en réalité.',
  }
};
