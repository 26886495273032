export const translations = {
  'en': {
    services: {
      header: "Welcome to SearchCar Hub",
      intro: "Discover the ultimate platform for buying and selling cars, where you can find the best deals and connect with trusted sellers.",
      videoProduction: {
        title: "Features",
        description: "SearchCar Hub offers advanced search filters, real-time price comparisons, and secure transaction processes to ensure a seamless car buying experience."
      },
      liveEventCoverage: {
        title: "Customer Support",
        description: "Our dedicated support team is here to assist you with any inquiries or issues you may have during your car buying or selling journey."
      },
      dronePhotography: {
        title: "",
        description: ""
      }
    }
  },
  'pt-br': {
    services: {
      header: "Bem-vindo ao SearchCar Hub",
      intro: "Descubra a plataforma definitiva para compra e venda de carros, onde você pode encontrar as melhores ofertas e se conectar com vendedores confiáveis.",
      videoProduction: {
        title: "Funcionalidades",
        description: "O SearchCar Hub oferece filtros de busca avançados, comparações de preços em tempo real e processos de transação seguros para garantir uma experiência de compra de carros tranquila."
      },
      liveEventCoverage: {
        title: "Suporte ao Cliente",
        description: "Nossa equipe de suporte dedicada está aqui para ajudar com quaisquer dúvidas ou problemas que você possa ter durante sua jornada de compra ou venda de carros."
      },
      dronePhotography: {
        title: "",
        description: ""
      }
    }
  },
  'pt': {
    services: {
      header: "Bem-vindo ao SearchCar Hub",
      intro: "Descubra a plataforma definitiva para comprar e vender carros, onde pode encontrar as melhores ofertas e conectar-se com vendedores de confiança.",
      videoProduction: {
        title: "Funcionalidades",
        description: "O SearchCar Hub oferece filtros de pesquisa avançados, comparações de preços em tempo real e processos de transação seguros para garantir uma experiência tranquila na compra de carros."
      },
      liveEventCoverage: {
        title: "Apoio ao Cliente",
        description: "A nossa equipa de suporte dedicada está aqui para ajudar com quaisquer dúvidas ou problemas que possa ter durante a sua jornada de compra ou venda de carros."
      },
      dronePhotography: {
        title: "",
        description: ""
      }
    }
  },
  'fr': {
    services: {
      header: "Bienvenue sur SearchCar Hub",
      intro: "Découvrez la plateforme ultime pour acheter et vendre des voitures, où vous pouvez trouver les meilleures offres et vous connecter avec des vendeurs de confiance.",
      videoProduction: {
        title: "Fonctionnalités",
        description: "SearchCar Hub offre des filtres de recherche avancés, des comparaisons de prix en temps réel et des processus de transaction sécurisés pour garantir une expérience d'achat de voitures sans faille."
      },
      liveEventCoverage: {
        title: "Support Client",
        description: "Notre équipe de support dédiée est là pour vous aider avec toutes les questions ou problèmes que vous pourriez rencontrer lors de votre parcours d'achat ou de vente de voitures."
      },
      dronePhotography: {
        title: "",
        description: ""
      }
    }
  }
};
