export const translations = {
  'en': {
    login: 'Login',
    slogan: 'Connecting you with your perfect car',
    login_description: 'Log in to access your account.',
  },
  'pt-br': {
    login: 'Login',
    slogan: 'Conectando você com o carro perfeito',
    login_description: 'Faça login para acessar sua conta.',
  },
  'pt': {
    login: 'Login',
    slogan: 'Conectando-o ao carro perfeito',
    login_description: 'Faça login para aceder à sua conta.',
  },
  'fr': {
    login: 'Connexion',
    slogan: 'Vous connecter à la voiture parfaite',
    login_description: 'Connectez-vous pour accéder à votre compte.',
  }
};
