import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';

export const Home: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const bgTheme = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light'
  return (
    <div>
      <section className={`text-center py-20 ${bgTheme}`}>
        <h1 className="text-4xl font-bold">{t('home:welcome')} {t('')}</h1>
        <p className="text-xl text-gray-600 mt-4">{t('home:slogan')}</p>
      </section>
      {/* Outras seções podem ser adicionadas aqui */}
    </div>
  );
}
